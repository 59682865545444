import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'private',
    loadChildren: () =>
      import('./pages/private/tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./pages/home/home.page').then((m) => m.HomePage),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.page').then((m) => m.LoginPage),
  },
  {
    path: 'cadastro',
    loadComponent: () =>
      import('./pages/cadastro/cadastro.page').then((m) => m.CadastroPage),
  },
  {
    path: 'esqueci-senha',
    loadComponent: () =>
      import('./pages/esqueci-senha/esqueci-senha.page').then(
        (m) => m.EsqueciSenhaPage
      ),
  },
  {
    path: 'redefinir-senha',
    loadComponent: () =>
      import('./pages/redefinir-senha/redefinir-senha.page').then(
        (m) => m.RedefinirSenhaPage
      ),
  },
];
