import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthenticateOptions,
  BiometricAuth,
  CheckBiometryResult,
} from '@aparajita/capacitor-biometric-auth';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { LoadingController, Platform } from '@ionic/angular';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { StorageService } from './storage.service';
import { ToastService } from './toast.service';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticationState = new BehaviorSubject(false);
  ticket: string | undefined;
  userData: any;
  unitData: any;

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public plt: Platform,
    private loadingCtrl: LoadingController,
    private toastService: ToastService,
    private platform: Platform
  ) {
    this.plt.ready().then(() => {
      this.checkTicket();
    });
  }

  async checkTicket() {
    const loading = await this.loadingCtrl.create({ message: 'Entrando' });
    loading.present();
    try {
      const ticket = await this.storage.get(TOKEN_KEY);
      const isValid = await this.verifyTicket(ticket);
      if (isValid) {
        this.ticket = ticket;
        this.userData = await this.storage.get('userData');

        // ler configurações da unidade
        const resUnit = await this.getUnitConfig(this.userData.unitId);
        if (resUnit.ok) {
          console.log('*** config', resUnit);

          this.storage.set('unitData', resUnit.satis);
          this.unitData = resUnit.satis;
        }

        const isBiometryOk = await this.handleBiometry();
        if (isBiometryOk) {
          this.authenticationState.next(true);
        } else {
          this.logout();
        }
      } else {
        this.authenticationState.next(false);
      }
    } catch (error) {
    } finally {
      loading.dismiss();
    }
  }

  async verifyTicket(ticket: string) {
    try {
      const response = await lastValueFrom(
        this.http.get<any>(
          `${env.baseURL}/Secure/verifyTicket?ticket=${ticket}`
        )
      );

      return response.isValid || response.ticket_valid == '1';
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  getTicket() {
    return this.ticket;
  }

  async login(user: string, password: string) {
    const loading = await this.loadingCtrl.create({ message: 'Entrando' });
    loading.present();

    try {
      const body = {
        f_user: user,
        f_password: password,
      };

      const res = await lastValueFrom(
        this.http.post<any>(`${env.baseURL}/Secure/validate`, body)
      );

      if (res.ok) {
        this.storage.set(TOKEN_KEY, res.ticket);
        this.storage.set('unitId', res.unitId);
        this.storage.set('userData', res);
        this.ticket = res.ticket;
        this.userData = res;

        // ler configurações da unidade
        const resUnit = await this.getUnitConfig(this.userData.unitId);

        if (resUnit.ok) {
          this.storage.set('unitData', resUnit.satis);
          this.unitData = resUnit.satis;
        }

        this.authenticationState.next(true);
      } else {
        if (res.error.indexOf('password does not match') > -1) {
          this.toastService.showError('Usuário ou senha inválidos');
        } else if (res.error_code == '110') {
          this.toastService.showError('Usuário ainda não ativo');
        } else {
          this.toastService.showError('Não foi possível fazer login');
        }
      }
    } catch (error) {
      this.toastService.showError('Não foi possível fazer login');
      console.log(error);
    } finally {
      loading.dismiss();
    }
    return this.authenticationState.value;
  }

  async getUnitConfig(unitId: string) {
    return lastValueFrom(
      this.http.post<any>(
        `${env.baseURL}/Satis/getUnidade?ticket=${this.ticket}`,
        {
          unidade_id: unitId,
        }
      )
    );
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  async logout() {
    const loading = await this.loadingCtrl.create({ message: 'Saindo' });
    loading.present();

    try {
      const url = `${env.baseURL}/Secure/logout?ticket=${this.ticket}`;
      const res = await lastValueFrom(this.http.get(url));

      await this.storage.remove(TOKEN_KEY);
      await this.storage.remove('userData');
      await this.storage.remove('unitId');
      await this.storage.remove('unitData');
      return this.authenticationState.next(false);
    } catch (error) {
      console.log(error);
    } finally {
      loading.dismiss();
      window.location.reload(); // experimental
    }
  }

  recoveryPassword(telefone: string): any {
    return lastValueFrom(
      this.http.post(`${env.baseURL}/Secure/recoveryPassword`, {
        fone_use: telefone,
      })
    );
  }

  resetPassword(codigo: string, senha: string): any {
    return lastValueFrom(
      this.http.post(`${env.baseURL}/Secure/resetPassword`, { codigo, senha })
    );
  }

  cadastro(userData: any): any {
    return lastValueFrom(
      this.http.post(`${env.baseURL}/Device/createUserDevice`, userData)
    );
  }

  changePassword(currentPassword: string, newPassword: string) {
    const body = {
      pass: currentPassword,
      newpass: newPassword,
    };

    return this.http.post<any>(
      `${env.baseURL}/Satis/changePassword?ticket=${this.ticket}`,
      body
    );
  }

  apagarDadosPessoais() {
    return this.http.post<any>(
      `${env.baseURL}/Satis/removeAccount?ticket=${this.ticket}`,
      null
    );
  }

  setupPush() {
    if (!this.platform.is('capacitor')) return;

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        console.log('permissão para push...');
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      console.log('registrando push com token: ', token);
      this.http.post(
        `${env.baseURL}/Secure/setRegistration?ticket=${this.ticket}`,
        {
          GCMRegistration: token,
        }
      );
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Error on registration: ', JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ', JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ', JSON.stringify(notification));
      }
    );
  }

  async handleBiometry() {
    let output = true;
    if (!this.platform.is('capacitor')) return output;

    const useBiometry = (await this.storage.get('useBiometric')) || false;

    if (useBiometry) {
      const result = await BiometricAuth.checkBiometry();
      if (result.isAvailable) {
        const options: AuthenticateOptions = {
          reason: 'Para tornar seu app mais seguro',
          cancelTitle: 'Cancelar',
          allowDeviceCredential: true,
          iosFallbackTitle: 'Usar o PIN',
          androidTitle: 'Verificação biométrica',
          androidSubtitle: 'para validar suas credenciais',
        };
        try {
          const auth = await BiometricAuth.authenticate(options);
          output = true;
        } catch (error) {
          output = false;
        }
      }
    }

    return output;
  }

  biometryResumeListener(info: CheckBiometryResult): void {
    this.handleBiometry().then((result) => {
      if (!result) {
        this.logout();
      }
    });
  }
}
