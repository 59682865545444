import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastCtrl: ToastController) {}

  async showError(err: any | string | Error) {
    if (!(err instanceof String)) {
      if (err.text) {
        err = err.text;
      } else if (err.error) {
        if (!(err.error instanceof String)) {
          err = err.error.text;
        } else {
          err = err.error;
        }
      }
    }
    if (!err || err.length < 1) {
      return;
    }

    const toast = await this.toastCtrl.create({
      message: err,
      position: 'bottom',
      color: 'danger',
      duration: 5000,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    toast.present();

    return toast;
  }

  async showSuccess(msg: string) {
    if (!msg || msg.length < 1) {
      return;
    }
    const toast = await this.toastCtrl.create({
      message: msg,
      position: 'bottom',
      duration: 2000,
      color: 'success',
    });
    toast.present();
    return toast;
  }

  async showInfo(msg: string) {
    if (!msg || msg.length < 1) {
      return;
    }
    const toast = await this.toastCtrl.create({
      message: msg,
      position: 'middle',
      duration: 4000,
      color: 'warning',
      cssClass: 'info',
    });
    toast.present();
    return toast;
  }

  async showMessage(msg: string, botao: string = 'OK') {
    if (!msg || msg.length < 1) {
      return;
    }
    const toast = await this.toastCtrl.create({
      message: msg,
      position: 'bottom',
      color: 'tertiary',
      buttons: [
        {
          text: botao,
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    toast.present();
    return toast;
  }
}
