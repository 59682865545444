import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PushNotifications } from '@capacitor/push-notifications';
import { IonicModule, Platform } from '@ionic/angular';
import { AuthService } from './services/auth.service';

import { StatusBar, Style } from '@capacitor/status-bar';
import { CommonModule } from '@angular/common';
import { BiometricAuth } from '@aparajita/capacitor-biometric-auth';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private auth: AuthService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        PushNotifications.removeAllDeliveredNotifications();
        StatusBar.setStyle({ style: Style.Dark });

        BiometricAuth.addResumeListener(this.auth.biometryResumeListener);
      }

      // define a rota a partir da autenticacao
      this.auth.authenticationState.subscribe((state) => {
        if (state) {
          this.router.navigate(['/private']);
        } else {
          this.router.navigate(['/home']);
        }
      });
    });
  }
}
